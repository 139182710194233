import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { useTranslation } from "react-i18next";

import {
  PageWrapper,
  ContentWrapper,
  Title,
  Description,
  DescriptionItem,
  DescriptionSubtitle,
  RowWrapper,
  LettersWrapper,
  LettersItem,
  Image,
  Image2,
} from "./style";
import { PageChange } from "../../../../../common/Scroll";
import Breakpoint from "../../../../../common/Breakpoint";
import { FluidImageType } from "../../../../../../utils/types";
import { PageTitle } from "../../../../../common/PageTitle";

interface LettersOKEProps {
  pageChange: PageChange;
}

const LettersOKE: React.FC<LettersOKEProps> = ({ pageChange }) => {
  const { t } = useTranslation("main");
  const data = useStaticQuery(lettersQuery);
  const description = (t(`eko_description`));
  const description_points = (t(`eko_description_points`));

  return (
    <>
      <PageTitle subtitle={t(`eko_title`)} />
      <PageWrapper animation={pageChange}>
          <RowWrapper>
            <ContentWrapper>
              <div>
                <Title>
                  <span>{t(`eko_title1`)} </span>
                  <span>{t(`eko_title2`)}</span>
                </Title>
                <Breakpoint device="tabletLandscape">
                  <LettersWrapper>
                    {data.allFile.nodes.map(
                      (img: FluidImageType, index: number) => (
                        <Image key={index} fluid={img.childImageSharp.fluid} />
                      )
                    )}
                  </LettersWrapper>
                </Breakpoint>
                  <Description>
                    <Description>{description}</Description>
                    {Object.values(description_points).map(o => 
                      <DescriptionItem>
                          <div>
                            <DescriptionSubtitle>
                              {o.subtitle}
                            </DescriptionSubtitle>
                            {' '}{o.text}
                          </div>
                      </DescriptionItem>)}
                  </Description>
              </div>
            </ContentWrapper>

            <Breakpoint device={["tablet", "computer"]}>
                
              <LettersWrapper>
                <LettersItem>
                  {data.allFile.nodes.map((img: FluidImageType, index: number) => (
                    <Image2 key={index} fluid={img.childImageSharp.fluid} />
                  ))}
                </LettersItem>
              </LettersWrapper>
            </Breakpoint>
          </RowWrapper>
      </PageWrapper>
    </>
  );
};

const lettersQuery = graphql`
  {
    allFile(
      filter: { relativeDirectory: { eq: "okeLetters" } }
      sort: { order: DESC, fields: [name] }
    ) {
      nodes {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  }
`;

export default LettersOKE;
