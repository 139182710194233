import styled, { css } from "styled-components";
import Img, { FluidObject } from "gatsby-image";

import { device } from "../../../../../../utils/breakpoints";
import {
  fadeIn,
  fadeOut,
  slideFromLeft,
  oAnimation,
  kAnimation,
  eAnimation,
} from "../../../../../../utils/animations";
import {
  H2,
  H3,
  SUBTITLE_2,
  TEXT_BODY_2,
} from "../../../../../../assets/styles/typography";

interface PageProps {
  animation?: string;
}

export const PageWrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  padding: 88px 178px 20px 88px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: #ffffff;
  animation: ${(props: PageProps) =>
    props.animation
      ? css`
          ${fadeOut} 300ms linear
        `
      : css`
          ${fadeIn} 300ms linear
        `};
  animation-fill-mode: forwards;

  @media ${device.computer} {
    padding: 128px 120px 120px 120px;
  }

  @media ${device.desktop} {
    padding: 128px 120px 120px 120px;
  } ;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media ${device.computer} {
    display: grid;
    grid-column: 1 / 6;
  }

  @media ${device.desktop} {
    grid-column: 1 / 5;
  } ;
`;

export const Title = styled.div`
  width: 100%;
  margin: 0;
  font-size: 2.2rem;
  line-height: 2.5rem;
  font-weight: 700;
  color: #151515;
  white-space: pre-line;
  opacity: 0;
  animation: ${fadeIn} 0.6s linear;
  animation-delay: 0.6s;
  animation-fill-mode: forwards;

  span:first-child {
    color: #013cff;
  }

  @media ${device.computer} {
    ${H3}
  }

  @media ${device.desktop} {
    ${H2}
  } ;
`;

export const Description = styled.div`
  ${TEXT_BODY_2}
  margin: 12px 0 0 0;
  display: block;
  color: #151515;
  white-space: pre-line;
  transform: translateX(-200%);
  animation: ${slideFromLeft} 0.6s ease-out;
  animation-delay: 1s;
  animation-fill-mode: forwards;

  @media ${device.computer} {
    margin-top: 48px;
  } ;
`;

export const DescriptionItem = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: row;

  @media ${device.computer} {
    margin-top: 16px;
  } ;
`;

export const DescriptionSubtitle = styled.div`
  ${SUBTITLE_2}
  display: inline-block;
`;

export const RowWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  &:first-of-type {
    flex: 1;
  }

  @media ${device.computer} {
    display: grid;
    grid-template-columns: repeat(12, 64px);
    grid-column-gap: 32px;
  }

  @media ${device.desktop} {
    grid-template-columns: repeat(12, 110px);
  } ;
`;

export const LettersWrapper = styled.div`
  position: relative;
  flex: 1;
  min-height: 36vw;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: -1;

  @media ${device.computer} {
    margin-top: 120px;
    display: grid;
    grid-column: 6 / 12;
    min-height: 0;
    align-items: flex-start;
  } ;
`;

export const LettersItem = styled.div`
  @media ${device.tablet} {
    position: relative;
    flex: 1;
    min-height: 36vw;
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: -1;
  }

  @media ${device.computer} {
    display: flex;
  }
`;

export const Image = styled(Img)<{
  fluid: FluidObject | FluidObject[];
}>`
  display: block;
  width: 30%;
  max-height: 80vh;
  margin: 0 2vw;

  img {
    object-fit: contain !important;
  }

  &:first-of-type {
    animation: ${eAnimation} 16s linear infinite;
    margin: 0 2vw 0 0;
  }

  &:nth-of-type(2) {
    animation: ${kAnimation} 16s linear infinite;
  }

  &:nth-of-type(3) {
    animation: ${oAnimation} 16s linear infinite;
    margin: 0 0 0 2vw;
  }
`;

export const Image2 = styled(Img) <{
    fluid: FluidObject | FluidObject[];
}>`
  display: block;
  width: 30%;
  max-height: 80vh;
  margin: 0 2vw;

  img {
    object-fit: contain !important;
  }

  &:first-of-type {
    margin: 0 2vw 0 0;
  }

  &:nth-of-type(2) {
  }

  &:nth-of-type(3) {
    margin: 0 0 0 2vw;
  }
`;
